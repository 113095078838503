<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        {{ this.$t('erp.lang_storageMovement') }}
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="this.headers" :items="this.logs">
          <template v-slot:item.user="{ item }">

            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left mr-3">
                  <v-avatar class="widget-content-left primary" size="40">
                    <img :src="item.user.avatarURI" alt="" class="rounded-circle"
                    >
                  </v-avatar>
                </div>
                <div class="widget-content-left flex2">
                  <div class="widget-heading">{{ item.user.first_name + " " + item.user.last_name }}</div>
                  <div class="widget-subheading opacity-7">{{ item.user.role }}</div>
                </div>
              </div>
            </div>

          </template>

          <template v-slot:item.time="{ item }">

                            <span :style="{
                                color: getRandomColor()
                            }" class="text-muted text-center">{{ item.time }}</span>

          </template>

          <template v-slot:item.action="{ item }">

            <strong :style="{
                                color: getRandomColor()
                            }" class="text-muted text-center">{{ item.action }}</strong>

          </template>

          <template v-slot:item.log="{ item }">
            <span>{{ $t('generic.lang_name').toUpperCase() }}: {{ item.log[0].NAME }}</span><br>
            <span>EAN: {{ item.log[0].EAN }}</span><br>
            <span>{{ $t('generic.lang_cashier_qty').toUpperCase() }}: {{ item.log[0].QTY }}</span><br>

          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "StockMovementComponent",
  data: () => {
    return {
      loading: false,
      logs: []
    }
  },
  mounted() {
    this.loadLogs();
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    loadLogs() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.STORAGELOGS.GETALL).then((res) => {
        this.logs = res.data;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_date') + "/" + this.$t('generic.lang_time'),
          value: "time"
        },
        {
          text: this.$t('settings.lang_operator'),
          value: "user"
        },
        {
          text: this.$t('generic.lang_action'),
          value: "action"
        },
        {
          text: this.$t('erp.lang_fromStorage'),
          value: "fromStorage"
        },
        {
          text: this.$t('erp.lang_toStorage'),
          value: "toStorage"
        },
        {
          text: this.$t('erp.lang_log'),
          value: "log"
        },
        {
          text: this.$t('erp.lang_userText'),
          value: "userText"
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>